export const useModalVideo = (url: string) => {
  const controller = useModal()
  const iframeSrc = computed(() => {
    const matches1 = url.match(/https?:\/\/www\.youtube\.com\/watch\?v=(?<videoId>[0-9A-Za-z_-]*).*/)
    if (matches1?.groups?.videoId) {
      return `https://www.youtube.com/embed/${matches1.groups.videoId}?autoplay=1&modestbranding=1&enablejsapi=1`
    }

    const matches2 = url.match(/https?:\/\/youtu\.be\/(?<videoId>[0-9A-Za-z_-]*).*/)
    if (matches2?.groups?.videoId) {
      return `https://www.youtube.com/embed/${matches2.groups.videoId}?autoplay=1&modestbranding=1&enablejsapi=1`
    }

    const matchesVimeo = url.match(/https?:\/\/vimeo\.com\/(?<videoId>[0-9A-Za-z_-]*).*/)
    if (matchesVimeo?.groups?.videoId) {
      return `https://player.vimeo.com/video/${matchesVimeo.groups.videoId}?autoplay=1`
    }

    return ''
  })

  return { controller, iframeSrc }
}
